import React from 'react'
import { graphql} from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"


const Landing = ({ data, pageContext }) => {
    const { locale } = pageContext;
    const landing = data.landing;
    const numeroItem = landing.scaricabili.length;
    return (
        <Layout locale={locale}>
            <SEO title={landing.titolo} robots="noindex,nofollow"/>
            <div className="row">
                <div className="col-12 text-center">
                    <h1 className="pagetitle">{landing.titolo}</h1>
                </div>
            </div>
            {!!landing.descrizione &&
                <div className="section section--top">
                    <div className="row">
                        <div className="col-12">
                            <div className="section__text"  dangerouslySetInnerHTML={{__html: landing.descrizione.childMarkdownRemark.html}} />
                        </div>
                    </div>
                </div>
            }
            <div className="section section--top landing">
                <div className="row">
                    {landing.scaricabili.map((item, index) => {
                        return (
                            <div className={`col-sm-6 col-lg-4 ${(numeroItem === 2 && index === 0)?"offset-lg-2":""}`}>
                                <div  key={item.contentful_id}  className="card card--shadow">
                                    <a rel="noopener noreferrer nofollow" target="_blank" href={item.allegato.file.url} className="card__copertinawrapper">
                                        <Img  className="card__overlay" fluid={item.copertina.fluid} />
                                    </a>
                                   
                                    <div className="card__title text-center">
                                        <a rel="noopener noreferrer nofollow" target="_blank" href={item.allegato.file.url}>{item.titolo}</a>
                                    </div>
                                    {item.sottotitolo &&
                                        <div className="card__didascalia text-center">{item.sottotitolo}</div>
                                    }
                                    <div className="card__download text-center">
                                        <a rel="noopener noreferrer nofollow" target="_blank" href={item.allegato.file.url}  className="btn-action">scarica</a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>        
        </Layout>
    )
}

export const query = graphql`
    query LandingQuery($contentful_id: String!, $locale: String!) {
        landing: contentfulLanding(contentful_id: {eq: $contentful_id},  node_locale: {eq: $locale}){
            titolo
            descrizione {
                childMarkdownRemark {
                    html
                }
            }
            scaricabili {
                titolo
                sottotitolo
                copertina {
                    title
                    fluid(maxWidth: 600) {
                        ...GatsbyContentfulFluid
                    }
                }
                allegato {
                    title
                    file {
                      url
                    }
                }
            }
        }  
    }
`;

export default Landing;
